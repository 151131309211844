/* Rotate animation */
.rotate {
  -webkit-animation: rotateIcon 2s linear infinite;
  -moz-animation: rotateIcon 2s linear infinite;
  animation: rotateIcon 2s linear infinite;
}

@keyframes rotateIcon {
  100% {
    transform: rotate(360deg)
  }
}

@-webkit-keyframes rotateIcon {
  100% {
    transform: rotate(360deg)
  }
}

@-moz-keyframes rotateIcon {
  100% {
    transform: rotate(360deg)
  }
}

/* Shake animation */
.shake {
  -webkit-animation: shakeIcon 0.82s linear infinite;
  -moz-animation: shakeIcon 0.82s linear infinite;
  animation: shakeIcon 0.82s linear infinite;
}

@keyframes shakeIcon {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}

@-webkit-keyframes shakeIcon {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}

@-moz-keyframes shakeIcon {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}

/* Beat animation */
.beat {
  -webkit-animation: beatIcon 0.82s linear infinite;
  -moz-animation: beatIcon 0.82s linear infinite;
  animation: beatIcon 0.82s linear infinite;
}
@keyframes beatIcon
{
  0% {
    transform: scale(.75);
  }

  20% {
    transform: scale(1);
  }

  40% {
    transform: scale(.75);
  }

  60% {
    transform: scale(1);
  }

  80% {
    transform: scale(.75);
  }

  100% {
    transform: scale(.75);
  }
}

@-webkit-keyframes beatIcon
{
  0% {
    transform: scale(.75);
  }

  20% {
    transform: scale(1);
  }

  40% {
    transform: scale(.75);
  }

  60% {
    transform: scale(1);
  }

  80% {
    transform: scale(.75);
  }

  100% {
    transform: scale(.75);
  }
}

@-moz-keyframes beatIcon
{
  0% {
    transform: scale(.75);
  }

  20% {
    transform: scale(1);
  }

  40% {
    transform: scale(.75);
  }

  60% {
    transform: scale(1);
  }

  80% {
    transform: scale(.75);
  }

  100% {
    transform: scale(.75);
  }
}
